import React from 'react';
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import OutlinedButtonWithIcon from './OutlinedButtonWithIcon';


const LinkToEvent = (props) => {
    const {link}= props; 

    const handleClick = () => {
        window.open(link, "_blank");
      };

  return (
    <OutlinedButtonWithIcon
    icon={<LinkRoundedIcon />}
    text="Enlace del evento"
    color="#b31d15"
    action={handleClick}
  />
  )
}

export default LinkToEvent