import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import MenuUser from "../navegation/MenuUser";
import { MenuRounded, Favorite } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { getNotificationService } from "../../../app/modules/Services/chat.services";
import UserDracmas from "../navegation/UserDracmas";
import useTheme from "../../../hooks/useTheme";
import Notifications from "../navegation/Notifications";
import { getNotificationFake } from "../../../redux/api/notifications";
import MenuOndemand from "./MenuOndemand";
import { Img } from "../../common/Image";
import AlabLogo from "../../../assets/media/aden/acropolis-lab-rojo_logo.png";
import GoBack from "../../../modules/acropolisCommon/components/GoBack";
import useLayout from "../../../hooks/useLayout";

const NavegationOndemand = (props) => {
  const { toggleMenu, menu, closeMenu } = props;
  // REDUX
  const { user, activeRol } = useSelector((state) => state.auth);
  const ondemand = useSelector((state) => state.ondemand.ondemand);
  const microlearnings = useSelector(
    (state) => state.microlearnings.microlearnings
  );

  const { secondary } = useTheme();

  // STATE
  // eslint-disable-next-line no-unused-vars
  const [messagesState, setMessagesState] = useState(false);
  const [notificationStateFake, setNotificationFake] = useState([]);
  const { menu: currentMenu } = useLayout();

  // EFFECTS
  useEffect(() => {
    if (microlearnings === null && user) {
      getMessages();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microlearnings]);

  useEffect(() => {
    if (notificationStateFake.length === 0) {
      getNotificacion();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTIONS
  const getMessages = async () => {
    const notification = await getNotificationService(user.partner_id);
    if (Array.isArray(notification.result)) {
      setMessagesState(notification.result);
    }
  };

  const getNotificacion = async () => {
    const response = await getNotificationFake(activeRol);
    if (!response.error) {
      let result = response.sort((a, b) => b.id - a.id);
      setNotificationFake(result);
    } else {
      console.error(response.error);
    }
  };

  // Recargar Notificaciones
  const refreshNotification = () => {
    getNotificacion();
  };

  // RETURN
  return (
    <>
      <Header color={secondary}>
        <LogoWrapper>
          <FakeIconButton
            onClick={toggleMenu}
            data-tut="reactour__inicio_mobile_ondemand"
          >
            <MenuRounded color="secondary" />
          </FakeIconButton>
          {!window.location.origin.includes("porvenir") && (
            <Link to="/campus">
              <Img loading="lazy" src={AlabLogo} alt="logo" h="53px" />
            </Link>
          )}
          <InCompanyWrapper company={ondemand?.section}>
            {!!ondemand && ondemand.logo_url !== "False" && (
              <Link to="/campus">
                <InCompany src={`${ondemand.logo_url}`} />
              </Link>
            )}
          </InCompanyWrapper>
          <GoBack title={currentMenu} />
        </LogoWrapper>
        <Nav>
          {/** Dracmas */}
          <UserDracmas />
          {/* Notificaciones de mensajes */}
          <FavWrapper>
            <IconButton size="large">
              <Favorite />
            </IconButton>
          </FavWrapper>
          {/* <Messages messages={messagesState} /> */}

          {/* Notificaciones */}
          <Notifications
            notifications={notificationStateFake}
            refreshNotification={refreshNotification}
          />

          {/** Menu del usuario */}
          <MenuUser />
        </Nav>
      </Header>
      <MenuOndemand menu={menu} closeMenu={closeMenu} />
    </>
  );
};

export default NavegationOndemand;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: fixed;
  right: 0;
  width: calc(100% - 115px);
  height: 70px;
  background-color: var(--secondary-one);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 999;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 100%;

  & > a > img:first-child {
    padding-right: 4px;
    border-right: 1px solid var(--primary-two);
  }

  @media (max-width: 425px) {
    column-gap: 0rem;
    justify-content: space-between;

    & > a > img:first-child {
      border-right: 0;
    }
  }
`;

const FakeIconButton = styled(IconButton)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  column-gap: 1rem;

  @media (max-width: 768px) {
    column-gap: 0.5rem;
    display: none;
  }
`;

const FavWrapper = styled.div`
  svg {
    color: var(--primary-two);
    font-size: 1.6rem;
  }
`;

const InCompanyWrapper = styled.div`
  display: flex;
  justify-content: ${(p) =>
    p.company === "Porvenir" ? "center" : "flex-start"};
  @media (max-width: 768px) {
    column-gap: 0.5rem;
    display: none;
  }
`;

const InCompany = styled.img`
  height: 40px;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;
