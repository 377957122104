import { IconButton } from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Text } from "../../../../../components/common/Texts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShareIcon from "@mui/icons-material/Share";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useTheme from "../../../../../hooks/useTheme";
import Skeleton from "@mui/material/Skeleton";

const SliderMicrolearning = (props) => {
    const { resources } = props;

    const { primary } = useTheme();

    const [currenntPosition, setCurrenntPosition] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [showPoster, setShowPoster] = useState(true);

    const handleNext = () => {
        setCurrenntPosition(
            currenntPosition === resources.length - 1 ? 0 : currenntPosition + 1
        );
    };

    const handleBack = () => {
        setCurrenntPosition(
            currenntPosition === 0 ? resources.length - 1 : currenntPosition - 1
        );
    };

    const handlePlay = () => {
        setShowPoster(false);
    };

    if (!resources) {
        return (
            <Container borderColor={primary}>
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="100%"
                    height="100%"
                    sx={{ borderRadius: "20px"}}
                />
            </Container>
        )
    }

    return (
        <Container bgColor={"#000"} borderColor={primary}>
            <VideoWrapper>
                {/* {showPoster && (
                    <PosterImage onClick={handlePlay}>
                        <img src={resources[currenntPosition]?.image_url} />
                    </PosterImage>
                )} */}
                <ReactPlayer
                    url={resources[currenntPosition]?.video_url}
                    controls={true}
                    width="100%"
                    height="100%"
                    playsinline
                    onPlay={handlePlay}
                />
            </VideoWrapper>
            <Body>
                <TextContainer>
                    <Text fontSize="24px" fontWeight={700} color={primary}>
                        {resources[currenntPosition]?.publication_name}
                    </Text>
                </TextContainer>
                <ButtonsWrapper>
                    <IconButton onClick={handleBack} size="large">
                        <ArrowBackIcon />
                    </IconButton>
                    <IconButton disabled size="large">
                        <ShareIcon />
                    </IconButton>
                    <IconButton onClick={handleNext} size="large">
                        <ArrowForwardIcon />
                    </IconButton>
                </ButtonsWrapper>
            </Body>
        </Container>
    );
};

export default SliderMicrolearning;

const Container = styled.div`
    width: 35%;
    height: 100%;
    background-color: ${(props) => props.bgColor};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    border: 1px solid ${(props) => props.borderColor};

    @media (width < 768px) {
        width: 100%;
    }
`

const VideoWrapper = styled.div`
    margin-top: 1rem;
    height: 60%;
    width: 100%;
    background-color: #333333;
    position: relative;
`;

const Body = styled.div`
    height: 40%;
    width: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
`;

const TextContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--secondary-two);
    border-radius: 0 0 20px 20px;
`;
