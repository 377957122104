import React from "react";
import styled from "styled-components";
import useTheme from "../../hooks/useTheme";
import { Icon } from '@iconify/react';
import LazyImg from "../../modules/acropolisCommon/components/LazyImg";

const Copyright = (props) => {
  const { padding } = props;
  const { primary } = useTheme();

  // RETURN
  return (
    <Container bgColor={primary} padding={padding}>
      {/* Usar componente LazyImg */}
      <LazyImg
        src="/assets/aden/logo-aden-simple-white.png"
        height="60px"
        width="auto"
        backgroundColor="transparent"
      />
      <TextWrapper>
        <Heading>
          Creado y desarrollado por © ADEN.
          {new Date().getFullYear()}
        </Heading>
        <SubHeding>
          Contáctanos en
          <Link href="mailto:equipo.acropolislab@aden.org">
            equipo.acropolislab@aden.org
          </Link>
        </SubHeding>
      </TextWrapper>
      <IconsWrapper>
        <RoundedIcon href="https://www.facebook.com/ADENBusinessSchool" target="_blank">
          <Icon icon="ri:facebook-fill" width="30" height="30"/>
        </RoundedIcon>
        <RoundedIcon href="https://twitter.com/ADENBS" target="_blank">
          <Icon icon="simple-icons:x" width="18" height="18"/>
        </RoundedIcon>
        <RoundedIcon href="https://www.instagram.com/adenbs/" target="_blank">
          <Icon icon="mdi:instagram" width="22" height="22"/>
        </RoundedIcon>
        <RoundedIcon href="https://www.linkedin.com/school/adenbs/" target="_blank">
          <Icon icon="basil:linkedin-solid" width="20" height="20" />
        </RoundedIcon>
        <RoundedIcon href="https://www.youtube.com/user/ADENBusinessSchool" target="_blank">
          <Icon icon="mdi:youtube" width="24" height="24"/>
        </RoundedIcon>
      </IconsWrapper>
    </Container>
  );
};

export default Copyright;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  row-gap: 1.5rem;
  margin: auto;
  width: 100%;
  height: 120px;
  background-color: #b31d15;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: start;
    height: 280px;
    padding-left: 2rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  @media (max-width: 768px) {
    align-items: start;
  }
`;

const Heading = styled.div`
  font-size: 16px;
  color: var(--secondary-one);
  font-weight: 500;
`;

const SubHeding = styled.div`
  display: flex;
  gap: 4px;
  font-size: 14px;
  color: var(--secondary-one);
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  svg {
    color: var(--secondary-one);
  }
`;

const Link = styled.a`
  color: var(--secondary-one);
`;

const RoundedIcon = styled.a`
  width: 30px;
  height: 30px;
  background-color: var(--secondary-one);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:first-child svg {
    margin-bottom: -8px;
  }
  :hover {
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
  }
  svg {
    color: #b31d15;
  }
`
