import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { getAdsByRolAndCompany } from "../../../../../redux/api/blog/blogApi";
import Skeleton from "@mui/material/Skeleton";

const AnnouncementsBlog = () => {

    const [sliderNumber, setSliderNumber] = useState(0);
    const [advertisements, serAdvertisements] = useState(null);

    const primaryColor = useSelector((state) => state?.ondemand?.ondemand?.primary_color);

    const { activeRol } = useSelector((state) => state.auth);
    const ondemand_id = useSelector((state) => state?.auth?.user?.ondemand_id);

    const company = activeRol === "ondemand" ? ondemand_id : null;

    //STATES

    //EFFECTS
    useEffect(() => {
        if (advertisements === null) {
            handleAds();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FUNCTIONS
    const handleAds = async () => {
        const response = await getAdsByRolAndCompany(company, activeRol);
        if (!!response.error) {
            serAdvertisements([]);
        } else {
            serAdvertisements(response.data);
        }
    };

    if (!advertisements) {
        return (
            <Container>
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="100%"
                    height="100%"
                    sx={{ borderRadius: "20px"}}
                />
            </Container>
        )
    }

    const blog = advertisements[sliderNumber];

    // console.log('blog :>> ', blog?.attributes?.bg_image?.data?.attributes?.formats?.large?.url);

    const handleClickLeft = () => {
        if (sliderNumber !== 0) {
            setSliderNumber(sliderNumber - 1);
        } else {
            setSliderNumber(advertisements.length - 1);
        }
    }

    const handleClickRight = () => {
        if (sliderNumber !== (advertisements.length - 1)) {
            setSliderNumber(sliderNumber + 1);
        } else {
            setSliderNumber(0);
        }
    }

    return (
        <Container backgroundColor={primaryColor}>
            <Cover img={blog?.attributes?.bg_image_url || `${process.env.REACT_APP_CMS_ADEN}${blog?.attributes?.bg_image?.data?.attributes?.url}` || ""}>
                <h2>{blog?.attributes?.title}</h2>
            </Cover>
            <Information>
                <h2><i>{blog?.attributes?.tag}</i></h2>
                <p>{blog?.attributes?.description}</p>
                {/* <Line />
                <label><b>11 de octubre - 4:00 pm</b></label> */}
                <a href={blog?.attributes?.link} target="_blank" rel="noreferrer"><b>Acceder al evento</b></a>
            </Information>
            <LeftButton onClick={handleClickLeft}>
                <Icon icon="fa6-solid:arrow-left" width="24px" height="24px" />
            </LeftButton>
            <RightButton onClick={handleClickRight}>
                <Icon icon="fa6-solid:arrow-right" width="24px" height="24px" />
            </RightButton>
        </Container>
    )
}

export default AnnouncementsBlog;

const Container = styled.div`
    position: relative;
    width: 62%;
    height: 100%;
    border-radius: 20px;
    /* background-image: ${(props) => `linear-gradient(180deg, ${props.backgroundColor} , #fff)`}; */
    background-color: ${(props) => props.backgroundColor};
    background-size: 100% 300%;
    display: flex;

    @media (width < 768px) {
        width: 100%;
        flex-direction: column;
    }
`

const Cover = styled.div`   
    width: 50%;
    height: calc(100% - 3rem);
    background-image: url(${(props) => props.img});
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    display: flex;
    align-items: start;
    justify-content: center;
    padding-top: 3rem;

    h2 {
        width: calc(315px - 4rem);
        font-size: 24px;
        background-color: var(--secondary-one);
        padding: 1rem 2rem;
        background-size: 200% 100%;
    }

    @media (width < 768px) {
        width: 100%;
        h2 {
            display: none;
        }
    }
`

const Information = styled.div`
    width: calc(50% - 6rem);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    color: var(--secondary-one);
    padding: 0 3rem;

    p {
        font-size: 20px;
        height: 135px;
        overflow-y: scroll;
        scrollbar-color: transparent transparent;
    }

    label {
        font-size: 20px;
    }

    a {
        cursor: pointer;
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary-one);
    }
`

const LeftButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #fff;
    color: var(--primary-one);
`

const RightButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #fff;
    color: var(--primary-one);
`