import { Route, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import SimpleLoading from "../components/common/SimpleLoading";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux/actions";

// Hooks
import { useQueryParams } from "../hooks/useQueryParams";

// Utils
import { decryptObject, /* encryptObject */ } from "../utils/encryptAndDecryptObject";

const AutoLoginRoute = (props) => {

	const dispatch = useDispatch();

	const { user } = useSelector((state) => state?.auth);
	const ondemand = useSelector((state) => state.ondemand.ondemand);

	const params = useQueryParams();
	const redirecturi = params.get("redirecturi");
	const rol = params.get("rol");
	const userParam = params.get("user");

	const DEFAULT_ROL = !!rol ? rol : "visita";

	const { push } = useHistory();

	const handleLogin = () => {
		// Funcion para encriptar un objeto
		/* encryptObject({ user: 'usuario.muestra@acropolislab.com', password: 'aden12345' }, 'clave-alab-auto1').then(encryptedString => {
			console.info("encriptado: ",encryptedString);
		}); */

		// Funcion para desencriptar un objeto 
		const encryptedString = userParam.replace(/ /g, "+"); // Se usa expresion regular para poner un + en lugar de un espacio porque cuando llegan por params los + se convierten en espacios (tener cuidado con los = tambien)
		decryptObject(encryptedString, 'clave-alab-auto1').then(decryptedObj => {
			if (DEFAULT_ROL === "ondemand") {
				dispatch(authActions.loginUserRequest(decryptedObj));
			}
		});
	};

	// EFFECTS
	useEffect(() => {
		if (!!ondemand) {
			push(`../${redirecturi}`);
		}
		if (!user) {
			handleLogin();
		} else {
			dispatch(authActions.getDataRequest(DEFAULT_ROL));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<>
			<Route
				{...props}
				render={() => {
					return (
						<Container>
							<SimpleLoading />
						</Container>
					);
				}}
			/>
		</>
	);
};

export default AutoLoginRoute;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	background-color: #fff;
`