import { Icon } from "@iconify/react";
import styled from "styled-components";
import * as encode from "nodejs-base64-encode";
import { useHistory } from "react-router-dom";
import GenericChips from "../../../common/GenericChip";
import BarProgress from "../../../common/BarProgress";
import { useSelector } from "react-redux";
import useTheme from "../../../../hooks/useTheme";

const CardSubjectCommon = (props) => {

  const { module, openCard, setOpenCard, last, nextSurvey, idCourse, order, grade, color, releaseSequence } = props;

  const hash = encode.encode(module.id + "", "base64");

  const user = useSelector((state) => state.auth.user);

  const history = useHistory();

  const { primary } = useTheme();

  const handleClick = () => {
    history.push(`/curso/${hash}?id=${idCourse}&origin=alab`);
  };

  const handleClickCertificate = () => {
    // Cambiar idcod
    window.open(
      `https://constancias.aden.org/?idcod=OA-${module?.id}-${user?.repo_id}-ADEN2024/`,
      "_blank"
    );
  }

  return (
    <Wrapper onStudy={module.onStudy} title={module.description} openCard={openCard}>
      <FirstInfo>
        <Header>
          {
            module?.rework_activities &&
            <ReworkActivities>
              <Icon icon="mingcute:alert-line" width="16px" height="16px" />
              Actividades por rehacer
            </ReworkActivities>
          }
          <FakeText>Estado: </FakeText>
          <GenericChips
            fontSize="13px"
            textTransform="capitalize"
            fontWeight="700"
            radius="8px"
            width="90px"
            textColor={module.progress >= 100 ? "#1E8065" : module.progress <= 0 ? "#C29F43" : "#5E80DB"}
            color={module.progress >= 100 ? "#EAFAF6" : module.progress <= 0 ? "#F9F5EC" : "#E6EBF9"}
            title={module.progress >= 100 ? "Finalizado" : module.progress <= 0 ? "Por iniciar" : "En curso"}
          />
        </Header>
        <Body>
          <Info>
            <Label>CURSO</Label>
            <Title color={color}>{order + 1}. {module.publication_name}</Title>
            <Check check={module.progress >= 100} color={primary}>
              <Icon icon="heroicons-outline:check" width="32px" height="32px" style={{ color: "var(--secondary-one)" }} />
              <Line openCard={openCard} last={last} nextSurvey={nextSurvey} color={primary} />
            </Check>
          </Info>
          <BarProgress value={module.progress > 100 ? 100 : module.progress} />
        </Body>
        <Footer enableGrade={!!grade}>
          <GoButton onClick={handleClick} color="primary" variant="contained" disabled={releaseSequence ? false : !module.onStudy} bgColor={color}>
            {module.progress >= 100 ? "Volver a ver" : module.progress <= 0 ? "Iniciar" : "Continuar cursado"}
          </GoButton>
          <Grade>Nota: <b>{grade || "-"}</b>/100</Grade>
          {
            module.plantilla_certificado_alab_id !== "" ?
            <CertificateButton disabled={module.progress !== 100} color={color} onClick={handleClickCertificate}>
              <Icon icon="la:award" width="54px" height="54px" />
            </CertificateButton>
            :
            <CertificateSpace />
          }

          <OpenCardButton onClick={() => setOpenCard(!openCard)}>
            {
              openCard ? <Icon icon="icon-park-outline:up" width="32px" height="32px" /> : <Icon icon="icon-park-outline:down" width="32px" height="32px" />
            }
          </OpenCardButton>
        </Footer>
      </FirstInfo>
      <SecondInfo openCard={openCard} color={color}>
        <p>{module.description}</p>
        <section>
          <Icon icon="mdi:clock" width="25px" height="25px" style={{ color: color }} />
          <b>{module?.duracion}</b> {module?.duracion_unidad_tiempo}
        </section>
      </SecondInfo>
    </Wrapper>
  )
}

export default CardSubjectCommon;


const Wrapper = styled.div`
  width: calc(100% - 4rem);
  height: ${(props) => props.openCard ? "226px" : "100px"};
  border-radius: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
  background-color: var(--secondary-one);
  transition: height .5s ease;

  @media (width < 768px) {
    width: 97%;
    height: 200px;
  }

`;

const FirstInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;

  @media (width < 768px) {
    flex-direction: column;
  }
`

const SecondInfo = styled.div`
  height: ${(props) => props.openCard ? "100%" : "0px"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 28px;
  padding: 0 2rem 1rem 2rem;
  transition: height 1s ease;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #535353;
  }

  section {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.color};

    b {
      margin-right: -4px;
    }
  }

  p {
    max-height: ${(props) => props.openCard ? "1000px" : "0"};
    overflow: hidden;
  }

  section {
    max-height: ${(props) => props.openCard ? "1000px" : "0"};
    overflow: hidden;
  }
`

const Header = styled.div`
  position: absolute;
  right: 0;
  top: -50px;
  height: 10px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const ReworkActivities = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  background-color: #F9F5EC;
  color: #C29F43;
  padding: .5rem;
  border-radius: 100px;
  border: 1px solid #C29F43;
`;

const Body = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 70px;
  width: 60%;
  padding: 1rem 2rem;

  @media (width < 768px) {
    flex-direction: column;
    width: 90%;
  }
`;

const Info = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 4px;
`

const Check = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left:${(props) => props.survey ? "-58px" : "-90px"};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.color};
  border-radius: 40px;
  background-color: ${(props) => props.check ? props.color : "var(--secondary-one)"};

  @media (width < 768px) {
    top: -35px;
    left: 90px;
    background-color: transparent;
  }
`

const Line = styled.div`
  width: 2px;
  height: ${(props) => props.last ? "0px" : (props.openCard && props.nextSurvey) ? "350px" : props.openCard ? "250px" : props.nextSurvey ? "230px" : "130px"};
  position: absolute;
  bottom: ${(props) => (props.openCard && props.nextSurvey) ? "-360px" : props.nextSurvey ? "-240px" : props.openCard ? "-260px" : "-140px"};
  background-color: ${(props) => props.color};
  transition: all .5s ease;

  @media (width < 768px) {
    display: none;
  }
`

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-two);
`

const Title = styled.h3`
  color: ${(props) => props.color};
  font-size: 18px;
  overflow-y: auto;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
`;

const Footer = styled.div`
  height: 70px;
  width: 35%;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 24px;

  @media (width < 768px) {
    width: 90%;
    gap: 12px;
  }
`;

const GoButton = styled.button`
  cursor: pointer;
  width: 160px;
  height: 40px;
  background-color: ${(props) => props.bgColor};
  border-radius: 48px;
  padding: 0 8px;
  font-size: 14px;
  color: var(--secondary-one);
  :disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
    :hover {
      box-shadow: none;
    }
    
  }
  :hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`

const Grade = styled.p`
  width: 110px;
  font-size: 16px;
  font-weight: 400;

  @media (width < 768px) {
    width: 140px;

    b {
      font-size: 16px;
    }
  }
`

const CertificateButton = styled.button`
  width: 54px;
  height: 54px;
  cursor: pointer;
  color: ${(props) => props.color};
  :disabled {
    color: #c4c4c4;
    cursor: not-allowed;
    :hover {
      background-color: var(--secondary-one);
    }
  }
  :hover {
    background-color: #DCDCDC;
    border-radius: 100px;
  }
`

const CertificateSpace = styled.button`
  width: 54px;
  height: 54px;
`

const OpenCardButton = styled.button`
  cursor: pointer;
  color: var(--primary-three);

  @media (width < 768px) {
    display: none;
  }
`

const FakeText = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-two);
`;
