import { useState } from "react";
import CardSubjectPreTest from "./CardSubjectPreTest.js";
import CardSubjectPostTest from "./CardSubjectPostTest.js";
import CardSubjectSurvey from "./CardSubjectSurvey.js";
import CardSubjectCommon from "./CardSubjectCommon.js";
import { useSelector } from "react-redux";

const CardSubjectModule = (props) => {
  const { module, idCourse, order, last, nextSurvey, color } = props;

  // REDUX
  const { enable_training_routes: release_sequence } = useSelector((state) => state?.ondemand?.ondemand);

  const [openCard, setOpenCard] = useState(false)

  if (module?.pre_test) {
    return (
      <CardSubjectPreTest
        module={module}
        openCard={openCard}
        last={last}
        color={color}
        releaseSequence={release_sequence}
      />
    );
  }

  if (module?.post_test) {
    return (
      <CardSubjectPostTest
        module={module}
        openCard={openCard}
        last={last}
        color={color}
        releaseSequence={release_sequence}
      />
    );
  }

  if (module.survey) {
    return (
      <CardSubjectSurvey
        module={module}
        openCard={openCard}
        last={last}
        color={color}
        releaseSequence={release_sequence}
      />
    );
  }

  return (
    <CardSubjectCommon
      module={module}
      openCard={openCard}
      setOpenCard={setOpenCard}
      last={last}
      nextSurvey={nextSurvey}
      idCourse={idCourse}
      order={order}
      grade={module?.grade}
      color={color}
      releaseSequence={release_sequence}
    />
  );
  // }
};

export default CardSubjectModule;